import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

//import  Layout  from './containers/Layout';
import Home from "./pages/Home";

import "./assets/css/tabler.css";

const Layout = React.lazy(()=>import('./containers/Layout'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Login = React.lazy(()=>import('./pages/Login'));

class App extends Component {
  render() {
    let defaultRoute
    if(!window.sessionStorage.getItem('SES_SURYA_APP')) {
      defaultRoute = 
       (<Route path="/" name="Home">
         <Redirect to="login"/>
       </Route>)
      
    } else {
      defaultRoute = ( <Route path="/" name="Home" render={props => <Layout {...props}/>} />)
    }
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login" render={props=><Login {...props} />} />
             {defaultRoute}
            </Switch>
          </React.Suspense>
        </HashRouter>
    )
  }
}

export default App;