import React from 'react';

const Home = () =>{
    console.log('Home');
    return (
        <React.Fragment>
            <div className='container-xl'>
                <p>Home</p>
            </div>
        </React.Fragment>
    )
}

export default Home;